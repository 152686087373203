
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="avatar list-avatar" [ngStyle]="{'background-color': getColor()}" *ngIf="!userProfile">
  <img draggable="false" [hidden]="hideImage" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (load)="onLoad()" (error)="imgLoadOnError()">
  <div *ngIf="user && user.firstLastCharacters && !avatarURL" class="profile-avtar">{{user.firstLastCharacters}}</div>
  <mat-icon *ngIf="((user && !user.firstLastCharacters && !avatarURL) || hideImage) && !isGroup" class="material-icons">person</mat-icon>
  <mat-icon *ngIf="((user && !user.firstLastCharacters && !avatarURL) || hideImage) && isGroup" class="material-icons">group</mat-icon>
</div>

<div class="avatar list-avatar" [ngStyle]="{'background-color': getColor()}" *ngIf="userProfile">
  <img draggable="false" [hidden]="hideImage" *ngIf="avatarURL" [src]="avatarURL" #imgElement validate="never" (load)="onLoad()" (error)="imgLoadOnError()">
</div>